








































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import TagComponent from '@/components/tag/TagComponent.vue';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import TagAddCreateModal from '@/components/modals/TagAddCreateModal.vue';
import { namespace } from 'vuex-class';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

const communityUserTagStore = namespace('CommunityUserTagStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    ConfirmModal,
    TagAddCreateModal,
    DropdownMenuItem,
    TagComponent,
  },
})
export default class EntityTagsWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  baseStoreName = 'EntityTagsWidgetStore';

  @Prop({ required: false, default: null })
  private readonly entityCode!: string;

  @Prop({ required: false, default: null })
  private readonly entityType!: string;

  @Prop({ required: false, default: () => [] })
  private readonly data!: Data[];

  @communityUserTagStore.Action
  private removeFromEntity!: (payload: {
    tagId: string;
    entityId: string;
  }) => Promise<CommunityUserTag | undefined>;

  @communityUserTagStore.Action
  private deleteCommunityUserTag!: (tagId: string) => Promise<boolean>;

  @communityUserTagStore.Action
  private updateCommunityUserTag!: (payload: CommunityUserTag) => Promise<CommunityUserTag | undefined>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private tags: CommunityUserTag[] = [];

  private openedMenu: number | null = null;

  private selectedTags: CommunityUserTag[] = [];

  private selectedIds: string[] = [];

  private tagToRemove: CommunityUserTag | null = null;

  private selectedTag: CommunityUserTag | null = null;

  created(): void {
    this.setDataConfig();
    this.setTags();
  }

  @Watch('data')
  setTags(): void {
    if (this.data
      && this.data.length > 0
      && this.data[0].tags) {
      this.tags = this.data[0].tags as CommunityUserTag[];
    }
    this.setSelectedUidList();
  }

  openMenu(index: number): void {
    if (this.openedMenu === index) {
      this.openedMenu = null;
    } else {
      this.openedMenu = index;
    }
  }

  onRenameTagClick(tag: CommunityUserTag): void {
    this.openedMenu = null;
    this.selectedTag = tag;
    this.$bvModal.show('tag-edit');
  }

  onDeleteTagClick(tag: CommunityUserTag): void {
    this.openedMenu = null;
    this.tagToRemove = tag;
    this.$bvModal.show('delete-entity-tag-0');
  }

  onRemoveTagClick(tag: CommunityUserTag): void {
    this.openedMenu = null;
    this.tagToRemove = tag;
    this.$bvModal.show('remove-entity-tag-0');
  }

  onAddClick(): void {
    if (this.authUser) {
      this.$bvModal.show('tag-add-create');
    } else {
      this.$router.push({ name: 'login' });
    }
  }

  private setSelectedUidList(): void {
    const uidList: string[] = [];
    this.tags.forEach((tag: CommunityUserTag) => {
      uidList.push(tag.uid);
    });
    this.selectedIds = uidList;
  }

  private onChangeColorClick(index: number, tag: CommunityUserTag): void {
    this.selectedTag = tag;
    // eslint-disable-next-line no-unused-expressions
    (this.$el.querySelector(`#colorPicker${index}`) as HTMLElement)?.click();
  }

  private onColorPickerClose(): void {
    if (this.selectedTag) {
      this.showToast(ToastActionType.TAGS_UPDATED);
      this.updateCommunityUserTag(this.selectedTag);
    }
  }

  private setColor(index: number, value: string): void {
    const tag = this.tags[index];
    tag.color = value;
    this.tags.splice(index, 1, tag);
  }

  private onDeleteConfirm(): void {
    if (this.tagToRemove) {
      this.deleteCommunityUserTag(this.tagToRemove.uid);
      const index = this.tags
        .findIndex((communityUserTag) => communityUserTag.uid === this.tagToRemove?.uid || '');
      if (index >= 0) {
        this.tags.splice(index, 1);
      }
      this.setSelectedUidList();
      this.tagToRemove = null;
    }
    this.showToast(ToastActionType.TAG_DELETED);
  }

  private onRemoveConfirm(): void {
    if (this.tagToRemove) {
      this.removeFromEntity({
        tagId: this.tagToRemove.uid,
        entityId: this.entityCode,
      });
      const index = this.tags
        .findIndex((communityUserTag) => communityUserTag.uid === this.tagToRemove?.uid || '');
      if (index >= 0) {
        this.tags.splice(index, 1);
      }
      this.setSelectedUidList();
      this.tagToRemove = null;
    }
    this.showToast(ToastActionType.TAG_REMOVED);
  }

  private modalConfirm(selectedList: CommunityUserTag[]): void {
    this.tags = selectedList;
    this.setSelectedUidList();
    this.showToast(ToastActionType.TAGS_UPDATED);
  }

  private onEditConfirm(payload: { newName: string; uid: string }): void {
    const index = this.tags
      .findIndex((communityUserTag) => communityUserTag.uid === this.selectedTag?.uid || '');
    if (index >= 0) {
      this.tags[index].name = payload.newName;
    }
    this.showToast(ToastActionType.TAG_RENAMED);
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
